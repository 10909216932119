<template>
  <v-card>
    <v-card-title>
      <v-menu open-on-hover bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <base-badge-counter
              v-if="tpCount"
              :itemCount="tpCount"
              :useIcon="true"
              :leftMargin="5"
              viewIcon="mdi-clipboard-list"
              tooltipText="Total number of Test Points"
            />
          </span>
        </template>
        <v-btn
          v-if="searchString"
          color="warning"
          text
          @click="filter2dRefresh(null)"
          >Remove filter</v-btn
        >
      </v-menu>
      <v-menu open-on-hover bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <base-badge-counter
              v-if="p100Count"
              :itemCount="p100Count"
              :useIcon="false"
              :leftMargin="5"
              :color="searchString === receptacle100 ? 'warning' : 'primary'"
              viewIcon="R100"
              tooltipText="Receptacles - 100 mil pitch"
            />
          </span>
        </template>
        <v-btn
          v-if="searchString !== receptacle100 && tpCount != p100Count"
          color="warning"
          text
          @click="filter2dRefresh(receptacle100)"
          >Apply R100 filter</v-btn
        >
      </v-menu>
      <v-menu open-on-hover bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <base-badge-counter
              v-if="p75Count"
              :itemCount="p75Count"
              :useIcon="false"
              :leftMargin="5"
              :color="searchString === receptacle75 ? 'warning' : 'primary'"
              viewIcon="R75"
              tooltipText="Receptacles - 75 mil pitch"
            />
          </span>
        </template>
        <v-btn
          v-if="searchString !== receptacle75 && tpCount != p75Count"
          color="warning"
          text
          @click="filter2dRefresh(receptacle75)"
          >Apply R75 filter</v-btn
        >
      </v-menu>
      <v-menu open-on-hover bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <base-badge-counter
              v-if="p50Count"
              :itemCount="p50Count"
              :useIcon="false"
              :leftMargin="5"
              :color="searchString === receptacle50 ? 'warning' : 'primary'"
              viewIcon="R50"
              tooltipText="Receptacles - 50 mil pitch"
            />
          </span>
        </template>
        <v-btn
          v-if="searchString !== receptacle50 && tpCount != p50Count"
          color="warning"
          text
          @click="filter2dRefresh(receptacle50)"
          >Apply R50 filter</v-btn
        >
      </v-menu>
      <v-menu open-on-hover bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <base-badge-counter
              v-if="p40Count"
              :itemCount="p40Count"
              :useIcon="false"
              :leftMargin="5"
              :color="searchString === receptacle40 ? 'warning' : 'primary'"
              viewIcon="R40"
              tooltipText="Receptacles - 40 mil pitch"
            />
          </span>
        </template>
        <v-btn
          v-if="searchString !== receptacle40 && tpCount != p40Count"
          color="warning"
          text
          @click="filter2dRefresh(receptacle40)"
          >Apply R40 filter</v-btn
        >
      </v-menu>
      <v-menu open-on-hover bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <base-badge-counter
              v-if="pEmptyCount"
              :itemCount="pEmptyCount"
              :useIcon="false"
              :leftMargin="5"
              :color="searchString === 1 ? 'warning' : 'primary'"
              viewIcon="NP"
              tooltipText="Not Populated. FixturFab detected these Test Points, but no Receptacles or Probes have been selected. Empty holes will be drilled."
              class="mr-5"
            />
          </span>
        </template>
        <v-btn
          v-if="searchString !== 1 && tpCount != pEmptyCount"
          color="warning"
          text
          @click="searchString = 1"
          >Apply NP filter</v-btn
        >
      </v-menu>
      <v-row align="center" class="ml-5">
        <v-col cols="12" md="3">
          <v-select
            :items="tipStyles"
            label="Filter by Tip Style"
            item-text="name"
            item-value="pk"
            v-model="selectedTipStyle"
            hide-details
            class="admin-label"
            clearable
            @change="filterByTipHandler"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            :items="testpointTypes"
            label="Filter by TestPoint Type"
            item-text="description"
            item-value="pk"
            v-model="selectedType"
            hide-details
            class="admin-label"
            clearable
            @change="filterByTypeHandler"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            :items="testpointLayers"
            label="Filter by TestPoint Layer"
            item-text="description"
            item-value="pk"
            v-model="selectedLayer"
            hide-details
            class="admin-label"
            clearable
            @change="filterByLayerHandler"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            label="Filter by Ref / Net"
            v-model="selectedRefNet"
            hide-details
            class="admin-label"
            clearable
            @change="filterByRefNetHandler"
          />
        </v-col>
      </v-row>
      <v-spacer />
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            dark
            top
            left
            color="warning"
            class="ma-2"
            v-on="on"
            v-show="isEditing"
            @click.stop="showSimpleDialog('discard')"
          >
            <v-icon>mdi-content-save-off</v-icon>
          </v-btn>
        </template>
        <span>Discard all changes</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            dark
            top
            left
            color="secondary"
            class="ma-2"
            v-on="on"
            v-show="isEditing"
            @click="saveAllInLine"
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>Save all changes</span>
      </v-tooltip>
      <TestPointForm
        :editMode="false"
        :override="override"
        :disabled="!isAdmin"
        :enabledViewMils="enabledViewMils"
        @saveData="saveForm"
      />
    </v-card-title>
    <v-alert
      v-if="
        fixtureConfig.development_fixture &&
          p100Count > 0 &&
          (p75Count > 0 || p50Count > 0)
      "
      dark
      dense
      color="secondary"
      class="mb-10 text-center"
    >
      <v-icon dark class="mr-2">mdi-alert</v-icon>
      An R100 is selected. In Development Fixtures, these are not compatible
      with R75 or R50 receptacles because of their Probe heights.
    </v-alert>
    <v-data-table
      v-model="selection"
      :headers="getHeaders(enabledViewMils)"
      :items="arrayList"
      :value="arrayList"
      :items-per-page="itemsPerPage"
      item-key="pk"
      :show-select="bulkEditMode"
      disable-filtering
      :item-class="itemRowBg"
      :loading="isLoading"
      loading-text="Loading data... Please wait"
      class="mb-5"
      :footer-props="pageOptions"
      ref="myTable2"
    >
      <template v-slot:[`item`]="props">
        <tr :class="itemRowBg(props.item)">
          <td v-if="bulkEditMode">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-checkbox
                    dense
                    hide-details
                    :input-value="props.isSelected"
                    @change="props.select($event)"
                  ></v-checkbox>
                </span>
              </template>
              <span>Bulk edit for drop-down selections</span>
            </v-tooltip>
          </td>
          <td>
            <v-edit-dialog
              :return-value.sync="props.item.ref"
              :large="isInitialState || isAdmin"
              @save="
                save('directSync', {
                  pk: props.item.pk,
                  data: {
                    propsKey: 'ref',
                    ref: props.item.ref,
                    edited: true,
                  },
                })
              "
              @cancel="cancel"
              ><span
                :class="
                  !props.item.ref && showerror
                    ? 'error--text'
                    : bulkEditMode
                    ? 'grey--text'
                    : ''
                "
                >{{
                  !props.item.ref && showerror ? "Required" : props.item.ref
                }}</span
              >
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.ref"
                  label="Reference"
                  type="text"
                  :disabled="!isInitialState && !isAdmin"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </td>
          <td>
            <v-edit-dialog
              :return-value.sync="props.item.net"
              :large="isInitialState || isAdmin"
              @save="
                save('directSync', {
                  pk: props.item.pk,
                  data: {
                    propsKey: 'net',
                    net: props.item.net,
                    edited: true,
                  },
                })
              "
              @cancel="cancel"
              ><span
                :class="
                  !props.item.net && showerror
                    ? 'error--text'
                    : bulkEditMode
                    ? 'grey--text'
                    : ''
                "
                >{{
                  !props.item.net && showerror ? "Required" : props.item.net
                }}</span
              >
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.net"
                  label="Net"
                  type="text"
                  :disabled="!isInitialState && !isAdmin"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </td>
          <td>
            <v-edit-dialog
              v-if="!enabledViewMils"
              :return-value="props.item.x"
              :large="isInitialState || isAdmin"
              @save="
                save('coordinate', {
                  pk: props.item.pk,
                  data: {
                    propsKey: 'x',
                    x: props.item.x,
                    y: props.item.y,
                    edited: true,
                  },
                })
              "
              @cancel="cancel('x', props.item.pk)"
              @close="cancel('x', props.item.pk)"
              ><span
                :class="
                  (!props.item.x || +props.item.x == 0) && showerror
                    ? 'error--text'
                    : ''
                "
                >{{
                  !props.item.x.toString().length > 0 && showerror
                    ? "Required"
                    : props.item.x
                }}</span
              >
              <template v-slot:input>
                <v-text-field
                  v-model.number="props.item.x"
                  label="X-Coordinate (mm)"
                  type="number"
                  step="0.0001"
                  :disabled="!isInitialState && !isAdmin"
                ></v-text-field>
              </template>
            </v-edit-dialog>
            <v-edit-dialog
              v-else
              :return-value="props.item.x_mils"
              :large="isInitialState || isAdmin"
              @save="
                save('coordinate_mils', {
                  pk: props.item.pk,
                  data: {
                    propsKey: 'x_mils',
                    x_mils: props.item.x_mils,
                    y_mils: props.item.y_mils,
                    edited: true,
                  },
                })
              "
              @cancel="cancel('x_mils', props.item.pk)"
              @close="cancel('x_mils', props.item.pk)"
              ><span
                :class="
                  (!props.item.x_mils || +props.item.x_mils == 0) && showerror
                    ? 'error--text'
                    : ''
                "
                >{{
                  !props.item.x_mils.toString().length > 0 && showerror
                    ? "Required"
                    : props.item.x_mils
                }}</span
              >
              <template v-slot:input>
                <v-text-field
                  v-model.number="props.item.x_mils"
                  label="X-Coordinate (mils)"
                  type="number"
                  step="0.0001"
                  :disabled="!isInitialState && !isAdmin"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </td>
          <td>
            <v-edit-dialog
              v-if="!enabledViewMils"
              :return-value="props.item.y"
              :large="isInitialState || isAdmin"
              @save="
                save('coordinate', {
                  pk: props.item.pk,
                  data: {
                    propsKey: 'y',
                    x: props.item.x,
                    y: props.item.y,
                    edited: true,
                  },
                })
              "
              @cancel="cancel('y', props.item.pk)"
              @close="cancel('y', props.item.pk)"
              ><span
                :class="
                  (!props.item.y || +props.item.y == 0) && showerror
                    ? 'error--text'
                    : ''
                "
                >{{
                  !props.item.y.toString().length > 0 && showerror
                    ? "Required"
                    : props.item.y
                }}</span
              >
              <template v-slot:input>
                <v-text-field
                  v-model.number="props.item.y"
                  label="Y-Coordinate (mm)"
                  type="number"
                  step="0.0001"
                  :disabled="!isInitialState && !isAdmin"
                ></v-text-field>
              </template>
            </v-edit-dialog>
            <v-edit-dialog
              v-else
              :return-value="props.item.y_mils"
              :large="isInitialState || isAdmin"
              @save="
                save('coordinate_mils', {
                  pk: props.item.pk,
                  data: {
                    propsKey: 'y_mils',
                    x_mils: props.item.x_mils,
                    y_mils: props.item.y_mils,
                    edited: true,
                  },
                })
              "
              @cancel="cancel('y_mils', props.item.pk)"
              @close="cancel('y_mils', props.item.pk)"
              ><span
                :class="
                  (!props.item.y_mils || +props.item.y_mils == 0) && showerror
                    ? 'error--text'
                    : ''
                "
                >{{
                  !props.item.y_mils.toString().length > 0 && showerror
                    ? "Required"
                    : props.item.y_mils
                }}</span
              >
              <template v-slot:input>
                <v-text-field
                  v-model.number="props.item.y_mils"
                  label="Y-Coordinate (mils)"
                  type="number"
                  step="0.0001"
                  :disabled="!isInitialState && !isAdmin"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </td>
          <td>
            <span v-if="!enabledViewMils" :class="bulkEditMode ? 'grey--text' : ''">{{
              props.item.min_spacing
            }}</span>
            <span v-else :class="bulkEditMode ? 'grey--text' : ''">{{
              props.item.min_spacing_mils
            }}</span>
          </td>
          <td>
            <v-edit-dialog
              :return-value="props.item.layer"
              :large="isInitialState || isAdmin"
              @save="
                save('layer', {
                  pk: props.item.pk,
                  data: {
                    pk: props.item.layerPk.pk || props.item.layerPk,
                    name: props.item.layerPk.description || props.item.layer,
                    edited: true,
                  },
                })
              "
              @cancel="cancel('layerPk', props.item.pk)"
              @close="cancel('layerPk', props.item.pk)"
              ><span
                :class="!props.item.layer && showerror ? 'error--text' : ''"
                >{{
                  !props.item.layer && showerror ? "Required" : props.item.layer
                }}</span
              >
              <template v-slot:input>
                <v-autocomplete
                  :items="testpointLayers"
                  label="Layer"
                  item-text="description"
                  item-value="pk"
                  v-model="props.item.layerPk"
                  return-object
                  :disabled="!isInitialState && !isAdmin"
                ></v-autocomplete>
              </template>
            </v-edit-dialog>
          </td>
          <td>
            <v-edit-dialog
              :return-value="props.item.type"
              :large="isInitialState || isAdmin"
              @save="
                save('tptype', {
                  pk: props.item.pk,
                  data: {
                    pk: props.item.typePk.pk || props.item.typePk,
                    name: props.item.typePk.description || props.item.type,
                    edited: true,
                  },
                })
              "
              @cancel="cancel('typePk', props.item.pk)"
              @close="cancel('typePk', props.item.pk)"
              ><span
                :class="!props.item.type && showerror ? 'error--text' : ''"
                >{{
                  !props.item.type && showerror ? "Required" : props.item.type
                }}</span
              >
              <template v-slot:input>
                <v-autocomplete
                  :items="testpointTypes"
                  label="Type"
                  item-text="description"
                  item-value="pk"
                  v-model="props.item.typePk"
                  return-object
                  :disabled="!isInitialState && !isAdmin"
                ></v-autocomplete>
              </template>
            </v-edit-dialog>
          </td>
          <td
            :class="
              !props.item.receptaclePk
                ? 'warning-style text-center'
                : 'text-center'
            "
          >
            <v-edit-dialog
              :return-value="props.item.receptacle"
              :large="isInitialState || isAdmin"
              @save="
                save('receptacle', {
                  pk: props.item.pk,
                  data: {
                    pk: props.item.receptaclePk.pk || props.item.receptaclePk,
                    name:
                      props.item.receptaclePk.description ||
                      props.item.receptacle,
                    pitch: props.item.receptaclePk.pitch || props.item.pitch,
                    edited: true,
                  },
                })
              "
              @cancel="cancel('receptaclePk', props.item.pk)"
              @close="cancel('receptaclePk', props.item.pk)"
              @open="receptacleHandler(props.item.min_spacing)"
              ><span
                :class="
                  !validReceptacle({
                    spacing: props.item.min_spacing,
                    receptacle: props.item.receptaclePk,
                  }) && showerror
                    ? 'error--text'
                    : ''
                "
                >{{
                  !props.item.receptacle && showerror
                    ? "Required"
                    : props.item.receptacle
                }}</span
              >
              <template v-slot:input>
                <v-autocomplete
                  :items="receptacleList"
                  label="Receptacle"
                  item-text="description"
                  item-value="pk"
                  v-model="props.item.receptaclePk"
                  return-object
                  :disabled="!isInitialState && !isAdmin"
                  no-data-text="Out of stock, contact us"
                ></v-autocomplete>
              </template>
            </v-edit-dialog>
          </td>
          <td
            :class="
              !props.item.probePk ? 'warning-style text-center' : 'text-center'
            "
          >
            <v-edit-dialog
              :return-value="props.item.probe"
              :large="isInitialState || isAdmin"
              @save="
                save('probe', {
                  pk: props.item.pk,
                  data: {
                    pk: props.item.probePk.pk || props.item.probePk,
                    name: props.item.probePk.description || props.item.probe,
                    tip_style:
                      props.item.probePk.tip_style || props.item.tip_style,
                    edited: true,
                  },
                })
              "
              @cancel="cancel('probePk', props.item.pk)"
              @close="cancel('probePk', props.item.pk)"
              @open="probeHandler(props.item.receptaclePk)"
            >
              <span
                :class="
                  !validProbe({
                    receptacle: props.item.receptaclePk,
                    probe: props.item.probePk,
                  }) && showerror
                    ? 'error--text'
                    : ''
                "
                >{{
                  !props.item.probe && showerror ? "Required" : props.item.probe
                }}</span
              >
              <template v-slot:input>
                <v-autocomplete
                  :items="probeList"
                  label="Probe"
                  item-text="description"
                  item-value="pk"
                  v-model="props.item.probePk"
                  return-object
                  :disabled="!isInitialState && !isAdmin"
                  no-data-text="Out of stock, contact us"
                ></v-autocomplete>
              </template>
            </v-edit-dialog>
          </td>
          <td>
            <v-edit-dialog
              :return-value.sync="props.item.enabled"
              :large="isInitialState || isAdmin"
              @save="
                save('enabled', {
                  pk: props.item.pk,
                  data: {
                    propsKey: 'enabled',
                    enabled: props.item.enabled,
                    edited: true,
                  },
                })
              "
              @cancel="cancel"
            >
              <v-chip
                :color="
                  isInitialState || isAdmin
                    ? props.item.enabled
                      ? 'secondary'
                      : 'warning'
                    : ''
                "
                >{{ props.item.enabled ? "Yes" : "No" }}</v-chip
              >
              <template v-slot:input>
                <v-select
                  :items="yesNo"
                  label="Enabled?"
                  item-text="text"
                  item-value="value"
                  v-model="props.item.enabled"
                  :disabled="!isInitialState && !isAdmin"
                ></v-select>
              </template>
            </v-edit-dialog>
          </td>
          <td class="text-center">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon
                  color="warning"
                  small
                  v-on="on"
                  v-show="props.item.edited"
                  class="mx-1"
                  @click.stop="showDialog(props.item, 'undo')"
                >
                  mdi-undo
                </v-icon>
              </template>
              <span>Undo Edit</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon
                  color="primary"
                  small
                  v-on="on"
                  v-show="props.item.edited"
                  class="mx-1"
                  @click.stop="showDialog(props.item, 'save')"
                >
                  mdi-content-save
                </v-icon>
              </template>
              <span>Save TestPoint</span>
            </v-tooltip>
            <TestPointForm
              :editMode="true"
              :override="override"
              :disabled="(!isInitialState && !isAdmin) || bulkEditMode"
              @saveData="saveForm"
              :itemData="cloneData(props.item)"
              :enabledViewMils="enabledViewMils"
            />
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-icon
                    color="warning"
                    small
                    class="mx-1"
                    :disabled="(!isInitialState && !isAdmin) || bulkEditMode"
                    @click.stop="showDialog(props.item, 'delete')"
                  >
                    mdi-delete
                  </v-icon>
                </span>
              </template>
              <span>{{
                isInitialState || isAdmin
                  ? "Delete TestPoint"
                  : "Delete is no longer available"
              }}</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-snackbar v-model="snack" top :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <BaseBottomDialog
      :dialog="bottomDialog"
      :message="confirmMessage"
      :action="confirmAction"
      @save="saveHandler"
      @delete="deleteHandler"
      @navToNext="navHandler"
      @update="saveAllInLine"
      @undo="undoHandler"
      @discard="discardHandler"
      @deleteRows="deleteSelected"
      @donothing="stopDialog"
    />
  </v-card>
</template>
<script>
import BottomDialog from "@/mixins/BottomDialog";
import PageOptions from "@/mixins/PageOptions";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ProbeConfigPage",
  mixins: [BottomDialog, PageOptions],
  props: {
    override: Boolean,
    showerror: Boolean,
    bulkEditMode: Boolean,
    isGerberExists: Boolean,
    isInitialState: Boolean,
    isAdmin: Boolean,
    gerberValid: Boolean,
    enabledViewMils: Boolean,
  },
  components: {
    TestPointForm: () => import("../components/TestPointForm.vue"),
  },
  data() {
    return {
      recepDialog: false,
      selectedItem: null,
      searchString: null,
      isLoading: false,
      snack: false,
      snackColor: "",
      snackText: "",
      selection: [],
      yesNo: [
        {
          value: true,
          text: "Yes",
        },
        {
          value: false,
          text: "No",
        },
      ],
      receptacleList: [],
      probeList: [],
      params: null,
      scale: 1,
      btnToggle: 1,
      showAll: true,
      showLabels: true,
      tpTopCheck: null,
      tpBottomCheck: null,
      layerFiles: null,
      receptacle40: 1.27,
      receptacle50: 1.91,
      receptacle75: 2.54,
      receptacle100: 2.55,
      selectedTipStyle: null,
      selectedType: null,
      selectedLayer: null,
      selectedRefNet: null,
    };
  },
  computed: {
    ...mapGetters({
      testPoints: "testpoints/testPoints",
      tpCount: "testpoints/tpCount",
      editedTP: "testpoints/editedTP",
      receptacles: "dutconfigs/receptacles",
      probes: "dutconfigs/probes",
      testpointLayers: "dutconfigs/testpointLayers",
      testpointTypes: "dutconfigs/testpointTypes",
      connectionType: "fixturedesign/connectionType",
      tipStyle: "fixturedesign/tipStyle",
      fixtureConfig: "fixturedesign/fixtureConfig",
      fixtureDesign: "fixturedesign/fixtureDesign",
      dutConfigs: "dutconfigs/dutConfigs",
      tipStyles: "projects/tipStyles",
      connectionTypes: "projects/connectionTypes",
      pressurePins: "pressurepins/pressurePins",
      pressureAssembly: "dutconfigs/pressurePins",
    }),
    isEditing() {
      return this.testPoints.find((element) => element.edited);
    },
    editedTestPoints() {
      return this.testPoints.filter((element) => element.edited);
    },
    arrayList() {
      let filteredTPs = [...this.testPoints];
      if (this.selectedTipStyle) {
        filteredTPs = this.testPoints.filter(
          (element) =>
            element.tip_style && element.tip_style === this.selectedTipStyle
        );
      }
      if (this.selectedType) {
        filteredTPs = this.testPoints.filter(
          (element) => element.type && element.typePk === this.selectedType
        );
      }
      if (this.selectedLayer) {
        filteredTPs = this.testPoints.filter(
          (element) => element.layer && element.layerPk === this.selectedLayer
        );
      }
      if (this.selectedRefNet) {
        filteredTPs = this.testPoints.filter(
          (element) => element.ref.toLowerCase().includes(this.selectedRefNet.toLowerCase()) || element.net.toLowerCase().includes(this.selectedRefNet.toLowerCase())
        );
      }
      if (!this.searchString) {
        return filteredTPs;
      } else if (this.searchString === 1) {
        return filteredTPs.filter((element) => !element.enabled);
      } else if (this.searchString === 9) {
        return filteredTPs.filter(
          (element) =>
            element.min_spacing &&
            element.min_spacing !== this.receptacle50 &&
            element.min_spacing !== this.receptacle75 &&
            element.min_spacing !== this.receptacle100
        );
      } else if (this.searchString === this.receptacle40) {
        return filteredTPs.filter(
          (element) => element.pitch ? element.pitch < this.receptacle40 : element.min_spacing < this.receptacle40
        );
      } else if (this.searchString === this.receptacle50) {
        return filteredTPs.filter(
          (element) => element.pitch ? element.pitch < this.receptacle50 && element.pitch >= this.receptacle40 : element.min_spacing < this.receptacle50 && element.min_spacing >= this.receptacle40
        );
      } else if (this.searchString === this.receptacle75) {
        return filteredTPs.filter(
          (element) => element.pitch ? element.pitch < this.receptacle75 && element.pitch >= this.receptacle50 : element.min_spacing < this.receptacle75 && element.min_spacing >= this.receptacle50
        );
      } else if (this.searchString === this.receptacle100) {
        return filteredTPs.filter(
          (element) => element.pitch ? element.pitch >= this.receptacle75 : element.min_spacing >= this.receptacle75
        );
      } else {
        return filteredTPs;
      }
    },
    p100Count() {
      return this.testPoints.filter(
        (element) => element.pitch ? element.pitch >= this.receptacle75 : element.min_spacing >= this.receptacle75
      ).length;
    },
    p75Count() {
      return this.testPoints.filter(
        (element) => element.pitch ? element.pitch < this.receptacle75 && element.pitch >= this.receptacle50 : element.min_spacing < this.receptacle75 && element.min_spacing >= this.receptacle50
      ).length;
    },
    p50Count() {
      return this.testPoints.filter(
        (element) => element.pitch ? element.pitch < this.receptacle50 && element.pitch >= this.receptacle40 : element.min_spacing < this.receptacle50 && element.min_spacing >= this.receptacle40
      ).length;
    },
    p40Count() {
      return this.testPoints.filter(
        (element) => element.pitch ? element.pitch < this.receptacle40 : element.min_spacing < this.receptacle40
      ).length;
    },
    pEmptyCount() {
      return this.testPoints.filter((element) => !element.enabled).length;
    },
  },
  methods: {
    getHeaders(inMils) {
      return [
        {
          text: "Reference",
          align: "start",
          value: "ref",
          width: "120",
          class: this.bulkEditMode
            ? "grey--text body-2 font-weight-bold"
            : "primary--text body-2 font-weight-bold",
        },
        {
          text: "Net",
          value: "net",
          width: "150",
          class: this.bulkEditMode
            ? "grey--text body-2 font-weight-bold"
            : "primary--text body-2 font-weight-bold",
        },
        {
          text: inMils ? "X-Coord (mils)" : "X-Coord (mm)",
          align: "start",
          value: "x",
          width: "140",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: inMils ? "Y-Coord (mils)" : "Y-Coord (mm)",
          align: "start",
          value: "y",
          width: "140",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: inMils ? "Clearance (mils)" : "Clearance (mm)",
          align: "start",
          value: "min_spacing",
          width: "160",
          class: this.bulkEditMode
            ? "grey--text body-2 font-weight-bold"
            : "primary--text body-2 font-weight-bold",
        },
        {
          text: "Layer",
          align: "start",
          value: "layer",
          width: "100",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Type",
          align: "start",
          value: "type",
          width: "150",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Receptacle",
          align: "center",
          value: "receptacle",
          width: "250",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Test Probe",
          align: "center",
          value: "probe",
          width: "350",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Enabled",
          align: "start",
          value: "enabled",
          width: "110",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Actions",
          align: "center",
          value: "actions",
          sortable: false,
          width: "130",
          class: "primary--text body-2 font-weight-bold",
        },
      ];
    },
    ...mapActions({
      removeTestPoint: "testpoints/deleteTestPoint",
      saveTestPoint: "testpoints/updateTestPoint",
      saveReceptacle: "testpoints/inlineReceptacle",
      saveProbe: "testpoints/updateProbe",
      saveLayer: "testpoints/updateLayer",
      saveType: "testpoints/updateType",
      saveCoordinate: "testpoints/updateCoordinate",
      saveCoordinateMils: "testpoints/updateCoordinateMils",
      saveInline: "testpoints/updateInline",
      saveDirectSync: "testpoints/updateDirectSync",
      saveAllEdited: "testpoints/updateAllEdited",
      undoChanges: "testpoints/restoreTestPoint",
      updateCell: "testpoints/revertCellObject",
      discardTestPoints: "testpoints/restoreAllTestPoints",
      removeSelectedTestPoints: "testpoints/deleteSelectedTestPoints",
      toggleLoading: "ui/loading",
    }),
    changeHandler(refname) {
      this.$refs[refname].save();
    },
    receptacleHandler(min_spacing) {
      this.receptacleList = (this.override
        ? this.receptacles
        : this.fixtureConfig.development_fixture &&
          (this.p75Count > 0 || this.p50Count > 0)
        ? this.receptacles.filter((element) => {
            return (
              element.pitch < this.receptacle100 &&
              element.fixtures.includes(this.fixtureConfig.pk)
            );
          })
        : this.receptacles.filter((element) => {
            return (
              element.pitch <= min_spacing &&
              element.fixtures.includes(this.fixtureConfig.pk)
            );
          })
      ).sort((a, b) => (a.description > b.description ? 1 : -1));
    },
    probeHandler(receptacle_pk) {
      this.probeList = (this.override
        ? this.probes
        : this.probes.filter((element) => {
            return element.receptacles.includes(receptacle_pk);
          })
      ).sort((a, b) => (a.description > b.description ? 1 : -1));
    },
    validProbe({ receptacle, probe }) {
      return this.probes
        .filter((element) => {
          return element.receptacles.includes(receptacle);
        })
        .map((item) => item.pk)
        .includes(probe);
    },
    validReceptacle({ spacing, receptacle }) {
      return this.receptacles
        .filter((element) => {
          return element.pitch <= spacing;
        })
        .map((item) => item.pk)
        .includes(receptacle);
    },
    cloneData(item) {
      return JSON.parse(JSON.stringify(item));
    },
    async saveHandler() {
      try {
        const actionPayload = {
          editMode: true,
          data: this.selectedItem,
        };
        await this.saveInline({
          payload: actionPayload,
          projectId: this.$route.params.id,
          fdId: this.fixtureDesign.pk,
        });
        if (this.isGerberExists && this.gerberValid) {
          this.$emit("updateRender");
        }
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
      this.stopDialog();
    },
    async deleteHandler() {
      try {
        await this.removeTestPoint({
          payload: this.selectedItem,
          dutId: this.$route.params.did,
          projectId: this.$route.params.id,
          fdId: this.fixtureDesign.pk,
        });
        if (this.isGerberExists && this.gerberValid) {
          this.$emit("updateRender");
        }
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
      this.stopDialog();
    },
    undoHandler() {
      this.stopDialog();
      this.undoChanges(this.selectedItem.pk);
      if (this.isGerberExists && this.gerberValid) {
        this.$emit("updateRender");
      }
    },
    showDialog(item, nextAction) {
      if (
        nextAction == "save" &&
        !this.override &&
        (!item.ref ||
          !item.net ||
          !item.x ||
          !item.y ||
          !item.min_spacing ||
          !item.layer ||
          !item.type ||
          !item.receptacle)
      ) {
        this.$store.commit(
          "ui/SNACK_BAR",
          "Some required data are missing or invalid."
        );
        return;
      }
      this.confirmAction = nextAction;
      this.confirmMessage =
        nextAction == "save" && this.enabledViewMils
          ? `You specified mils, but FixturFab works in mm. Displaying float values in mils, but each will be converted to mm upon saving.`
          : `Are you sure to ${nextAction} TestPoint with reference ${item.ref}?`;
      this.selectedItem = item;
      this.bottomDialog = true;
    },
    showSimpleDialog(nextAction) {
      this.confirmAction = nextAction;
      this.confirmMessage = `Are you sure to ${nextAction} all changes?`;
      this.bottomDialog = true;
    },
    save(type, payload) {
      if (type === "receptacle") {
        if (
          this.selection.length > 1 &&
          this.selection.find((item) => item.pk == payload.pk)
        ) {
          this.bulkHandler({ payload, fieldName: "saveReceptacle" });
        } else {
          this.saveReceptacle(payload);
        }
      } else if (type === "probe") {
        if (
          this.selection.length > 1 &&
          this.selection.find((item) => item.pk == payload.pk)
        ) {
          this.bulkHandler({ payload, fieldName: "saveProbe" });
        } else {
          this.saveProbe(payload);
        }
        if (this.isGerberExists && this.gerberValid) {
          this.$emit("updateRender");
        }
      } else if (type === "layer") {
        if (
          this.selection.length > 1 &&
          this.selection.find((item) => item.pk == payload.pk)
        ) {
          this.bulkHandler({ payload, fieldName: "saveLayer" });
        } else {
          this.saveLayer(payload);
        }
        this.filter2dRefresh();
      } else if (type === "tptype") {
        if (
          this.selection.length > 1 &&
          this.selection.find((item) => item.pk == payload.pk)
        ) {
          this.bulkHandler({ payload, fieldName: "saveType" });
        } else {
          this.saveType(payload);
        }
      } else if (type === "coordinate") {
        if (
          this.selection.length > 1 &&
          this.selection.find((item) => item.pk == payload.pk)
        ) {
          this.bulkHandler({ payload, fieldName: "saveCoordinate" });
        } else {
          this.saveCoordinate(payload);
        }
        if (this.isGerberExists && this.gerberValid) {
          this.$emit("updateRender");
        }
      } else if (type === "coordinate_mils") {
        if (
          this.selection.length > 1 &&
          this.selection.find((item) => item.pk == payload.pk)
        ) {
          this.bulkHandler({ payload, fieldName: "saveCoordinateMils" });
        } else {
          this.saveCoordinateMils(payload);
        }
        if (this.isGerberExists && this.gerberValid) {
          this.$emit("updateRender");
        }
      } else if (type === "enabled") {
        if (
          this.selection.length > 1 &&
          this.selection.find((item) => item.pk == payload.pk)
        ) {
          this.bulkHandler({ payload, fieldName: "saveDirectSync" });
        } else {
          this.saveDirectSync(payload);
        }
      } else {
        this.saveDirectSync(payload);
      }
    },
    cancel(type, pk) {
      if (type && pk) this.updateCell({ type, pk });
    },
    close() {
      this.$refs.myTable.$el.click();
      this.recepDialog = false;
    },
    nothing() {
      console.log("nothing");
    },
    async saveForm(payload) {
      try {
        await this.saveTestPoint({
          payload: payload,
          dutId: this.$route.params.did,
          projectId: this.$route.params.id,
          fdId: this.fixtureDesign.pk,
        });
        if (this.isGerberExists && this.gerberValid) {
          this.$emit("updateRender");
        }
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveAllInLine() {
      const validator = this.editedTestPoints.every((testpoint) => {
        if (
          !this.override &&
          (!testpoint.ref ||
            !testpoint.net ||
            !testpoint.x ||
            !testpoint.y ||
            !testpoint.layer ||
            !testpoint.type ||
            !testpoint.receptacle ||
            !testpoint.probe)
        ) {
          this.$store.commit(
            "ui/SNACK_BAR",
            "Some required data are missing or invalid."
          );
          return false;
        }
        return true;
      });
      if (!validator) return;
      const actionPayload = this.editedTestPoints.map((edited) => ({
        pk: edited.pk,
        project: edited.project,
        ref: edited.ref,
        name: edited.name,
        net: edited.net,
        x: edited.x,
        y: edited.y,
        min_spacing: edited.min_spacing,
        layer: edited.layerPk,
        type: edited.typePk,
        receptacle: edited.receptaclePk,
        probe: edited.probePk,
        enabled: edited.enabled,
      }));
      try {
        await this.saveAllEdited({
          arrayList: {
            project_id: this.$route.params.id,
            dut_id: this.dutConfigs.pk,
            data: {
              test_points: actionPayload,
            },
            data_type: "test-points",
          },
          projectId: this.$route.params.id,
          fdId: this.fixtureDesign.pk,
          testpoints: [...this.editedTestPoints],
        });
        if (this.isGerberExists && this.gerberValid) {
          this.$emit("updateRender");
        }
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
      this.stopDialog();
    },
    discardHandler() {
      this.stopDialog();
      this.discardTestPoints();
      if (this.isGerberExists && this.gerberValid) {
        this.$emit("updateRender");
      }
    },
    emitHandler(params) {
      if (this.isEditing) {
        this.confirmAction = "navToNext";
        this.confirmMessage =
          "Are you sure to navigate away from current page? (Changes to highlighted rows may be discarded)";
        this.params = params;
        this.bottomDialog = true;
      } else {
        this.proceedEmit(params);
      }
    },
    itemRowBg(item) {
      return item.edited ? "edited-style" : "";
    },
    navHandler() {
      this.stopDialog();
      this.proceedEmit(this.params);
    },
    proceedEmit(params) {
      this.$emit("nextStep", params);
    },
    filterByTipHandler() {
      this.selectedType = null;
      this.selectedLayer = null;
      this.selectedRefNet = null;
      this.filter2dRefresh("noFilter");
    },
    filterByTypeHandler() {
      this.selectedTipStyle = null;
      this.selectedLayer = null;
      this.selectedRefNet = null;
      this.filter2dRefresh("noFilter");
    },
    filterByLayerHandler() {
      this.selectedTipStyle = null;
      this.selectedType = null;
      this.selectedRefNet = null;
      this.filter2dRefresh("noFilter");
    },
    filterByRefNetHandler() {
      this.selectedTipStyle = null;
      this.selectedType = null;
      this.selectedLayer = null;
      this.filter2dRefresh("noFilter");
    },
    bulkHandler({ payload, fieldName }) {
      this.toggleLoading("Updating");
      for (let i = 0; i < this.selection.length; i++) {
        this[fieldName]({
          pk: this.selection[i].pk,
          data: payload.data,
        });
      }
      this.toggleLoading();
    },
    initSelection() {
      this.selection.splice(0);
    },
    deleteSelectedDialog() {
      if (this.selection.length > 0) {
        this.confirmAction = "deleteRows";
        this.confirmMessage = `Are you sure to delete all selected rows?`;
        this.bottomDialog = true;
      } else {
        this.$store.commit("ui/SNACK_BAR", "No rows to delete.");
      }
    },
    async deleteSelected() {
      this.stopDialog();
      try {
        await this.removeSelectedTestPoints({
          payload: this.selection,
          dutId: this.$route.params.did,
          projectId: this.$route.params.id,
          fdId: this.fixtureDesign.pk,
        });
        this.selection.splice(0);
        if (this.isGerberExists && this.gerberValid) {
          this.$emit("updateRender");
        }
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    filter2dRefresh(pitch) {
      if (pitch != "noFilter") this.searchString = pitch;
      this.$emit("updateFilter", this.arrayList);
    },
    onInputString() {
      return "if (this.value > 2147483647) this.value = 2147483647; if (this.value < 0) this.value = 0;"
    }
  },
  mounted() {
    if (this.isGerberExists && this.gerberValid) {
      this.filter2dRefresh(null);
    }
  },
};
</script>
